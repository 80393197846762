<template>
	<c-dialog ref="dialog" :title="'订单：' + orderNumber + ' - 发货'" width="800" @resolve="submit">
		<c-table ref="table" height="500" :paging="false">
			<c-table-column
				type="selector"
				width="20"
			></c-table-column>

			<c-table-column
				label="颜色"
				width="80"
				name="product_color"
				v-if="systemConfig.system_size_enable=='ON'"
				:row-span="({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.product_color == data.product_color){
							span_count ++;
						}
					});
					return span_count;
				}"
			>
			</c-table-column>
			<c-table-column
				label="颜色"
				width="80"
				name="product_spec"
				:row-span="({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
							span_count ++;
						}
					});
					return span_count;
				}"
			>
			</c-table-column>
			<c-table-column
				label="尺码"
				width="80"
				name="product_size"
				v-if="systemConfig.system_size_enable=='ON'"
			>
			</c-table-column>

			<c-table-column
				label="库存量"
				width="80"
				name="stock_count"
			>
			</c-table-column>

			<c-table-column
				label="下单量"
				width="80"
				name="product_count"
			>
			</c-table-column>
			<c-table-column
				label="已发货数"
				width="80"
				name="send_count"
			>
			</c-table-column>
			<c-table-column
				label="发货数"
				width="80"
				name="new_send_count"
			>
				<template v-slot="{data}">
					<c-input v-model="data.new_send_count" type="number" :range="[0,data.product_count-data.send_count]"></c-input>
				</template>
			</c-table-column>
		</c-table>
	</c-dialog>
</template>

<script>
	import {mapState} from 'vuex'
	export default {
		computed: {
			...mapState(['systemConfig']),
		},
		
		data() {
			return {
				orderNumber: ''
			}
		},
		
		methods: {
			open(data) {
				this.orderNumber = data.order_number;
				this._id = data.id;
				this.$refs.dialog.open();
				this.$refs.table.load({
					url: 'order/deliver/product/list',
					data: {
						id: data.id,
					},
					dataFormatter: data => {
						data.forEach(item => {
							item.new_send_count = item.product_count-item.send_count
						});
						return data;
					}
				});
			},
			
			submit(stop) {
				var flag = true;
				var data = this.$refs.table.getRow('selected');
				if (!data.length) {
					stop();
					this.$message({
						message: '请选择需要发货的商品'
					});
					return;
				};
				var list = Array.from(data, item => item.data);
				list.forEach(item => {
					var new_send_count = parseFloat(item.new_send_count||0);
					if(new_send_count<=0){
						stop();
						this.$message({message: '发货数量必须大于0'});
						flag = false;
						return;
					}
					var send_count = parseFloat(item.send_count||0);
					var product_count = parseFloat(item.product_count||0);
					if (product_count < (send_count + new_send_count)){
						stop();
						this.$message({message: '发货数量不能超过计划生产数量'});
						flag = false;
						return;
					}
				});
				//执行订单发货
				if (flag){
					this.request({
						url: 'order/deliver',
						data: {
							id: this._id,
							product_list:list
						},
						loading: true,
						success: data => {
							this.$message({
								type: 'success',
								message: '操作成功'
							});
							this.$emit('resolve');
						}
					});
				}
			}
		}
	}
</script>