<template>
	<page>
		<module>
			<c-title>基本信息</c-title>
			<c-detail column="2" style="max-width:1100px;">
				<c-detail-item label="订单编号" colspan="2">
					{{detailInfo.order_number}}
				</c-detail-item>
				<c-detail-item label="下单客户">
					{{detailInfo.customer_name}}
				</c-detail-item>
				<c-detail-item label="客户货号">
				     {{detailInfo.customer_product_code}}
				</c-detail-item>
				<c-detail-item label="产品名称">
				     {{detailInfo.product_name}}
				</c-detail-item>
<!--				<c-detail-item label="产品编码">
				    {{detailInfo.product_code}}
				</c-detail-item>-->
				<c-detail-item label="品牌名称">
				    {{detailInfo.brand_name}}
				</c-detail-item>
				<c-detail-item label="品牌LOGO">
					<c-file v-if="detailInfo.brand_logo" media :src="detailInfo.brand_logo" style="width:30px;height:30px;"></c-file>
				</c-detail-item>
				<c-detail-item v-if="detailInfo.production_json" label="订单生产要求">
					<div v-for="(item, i) in detailInfo.production_json" :key="i">{{i}}：{{item}}</div>
				</c-detail-item>
				<c-detail-item v-if="detailInfo.product_json" label="产品生产参数">
					<div v-for="(item, i) in detailInfo.product_json" :key="i">{{i}}：{{item}}</div>
				</c-detail-item>
				<c-detail-item v-if="detailInfo.product_remark" label="产品生产说明"  colspan="2">
					{{detailInfo.product_remark}}
				</c-detail-item>
				<c-detail-item label="交货日期" colspan="2">
					{{detailInfo.delivery_schedule_date}}
				</c-detail-item>
			</c-detail>
			<c-title>订货信息</c-title>
			<c-detail style="max-width:1100px;">
				<c-table ref="table" :data="productData" border >
					<c-table-column
						label="颜色"
						name="product_color"
						width="120"
						v-if="systemConfig.system_size_enable=='ON'"
						:row-span="({data},list)=>{
							var span_count = 0;
							list.forEach(item => {
								if(item.data.product_color == data.product_color){
									span_count ++;
								}
							});
							return span_count;
						}"
					>
					</c-table-column>
					<c-table-column
						label="颜色"
						width="80"
						name="product_spec"
						v-if="systemConfig.system_size_enable=='ON'"
						:row-span="({data},list)=>{
							var span_count = 0;
							list.forEach(item => {
								if(item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
									span_count ++;
								}
							});
							return span_count;
						}"
					>
					</c-table-column>
					<c-table-column
						label="尺码"
						width="80"
						name="product_size"
						v-if="systemConfig.system_size_enable=='ON'"
					>
					</c-table-column>
					<c-table-column
						label="数量"
						name="product_count"
						width="120"
					>
					</c-table-column>
					<c-table-column
						label="已发货"
						name="send_count"
						width="120"
					>
					</c-table-column>
					<c-table-column
						label="单价"
						name="product_unit_price"
						width="120"
					>
					</c-table-column>
					<c-table-column
						label="小计"
						name="product_money"
						width="120"
					>
					</c-table-column>
				</c-table>
			</c-detail>
			
			<template slot="button">
				<c-button v-if="auth('m_create_order') && detailInfo.state == -2" size="form" @click="navigateTo(`/order/edit/${detailInfo.id}`)">编辑</c-button>
				<c-button v-if="auth('b_order_transfer') && detailInfo.state == -1" color="main" size="form" @click="navigateTo(`/order/task/${detailInfo.id}`)">派工</c-button>
				<c-button v-if="auth('b_order_deliver') && (detailInfo.state == 1||detailInfo.state == 2)" color="main" size="form" @click="$refs.deliverDialog.open(detailInfo)">发货</c-button>
			</template>
		
			<order-deliver ref="deliverDialog" @resolve="getOrderDetail"></order-deliver>
		</module>
	</page>
</template>

<script>
	import orderDeliver from './order_deliver.vue'
	import {mapState} from 'vuex'

	export default {
		name: 'm_order_detail',
		
		components: {
			orderDeliver
		},
		
		props: {
			id: String
		},
		
		data() {
			return {
				detailInfo: '',
				paramList:[],
				productData: []
			}
		},
		computed: {
			...mapState(['sizeConfig','systemConfig']),
			
		},

		watch: {
			id() {
				this.paramList = [];
				this.productData = [];
				this.getOrderDetail();
			}
		},

		mounted() {
			if(this.id){
				this.getOrderDetail();
			}
		},

		methods: {
			//查询订单详情
			getOrderDetail() {
				this.request({
					url: '/order/detail',
					data: {
						id:this.id
					},
					loading: true,
					success: data => {
						this.detailInfo = data;
						this.productData = data.product_list;
					}
				});
			}
		}
	}
</script>

<style lang="stylus" scoped>
</style>