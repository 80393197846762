var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-dialog',{ref:"dialog",attrs:{"title":'订单：' + _vm.orderNumber + ' - 发货',"width":"800"},on:{"resolve":_vm.submit}},[_c('c-table',{ref:"table",attrs:{"height":"500","paging":false}},[_c('c-table-column',{attrs:{"type":"selector","width":"20"}}),(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":"颜色","width":"80","name":"product_color","row-span":({data},list)=>{
				var span_count = 0;
				list.forEach(item => {
					if(item.data.product_color == data.product_color){
						span_count ++;
					}
				});
				return span_count;
			}}}):_vm._e(),_c('c-table-column',{attrs:{"label":"颜色","width":"80","name":"product_spec","row-span":({data},list)=>{
				var span_count = 0;
				list.forEach(item => {
					if(item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
						span_count ++;
					}
				});
				return span_count;
			}}}),(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":"尺码","width":"80","name":"product_size"}}):_vm._e(),_c('c-table-column',{attrs:{"label":"库存量","width":"80","name":"stock_count"}}),_c('c-table-column',{attrs:{"label":"下单量","width":"80","name":"product_count"}}),_c('c-table-column',{attrs:{"label":"已发货数","width":"80","name":"send_count"}}),_c('c-table-column',{attrs:{"label":"发货数","width":"80","name":"new_send_count"},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [_c('c-input',{attrs:{"type":"number","range":[0,data.product_count-data.send_count]},model:{value:(data.new_send_count),callback:function ($$v) {_vm.$set(data, "new_send_count", $$v)},expression:"data.new_send_count"}})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }